import classnames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	className?: string;
	name: string;
	handleChange: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
	label,
	className,
	name,
	handleChange,
	...rest
}) => (
	<div
		className={classnames('form-row form-row--checkbox', className)}>
		<input
			type="checkbox"
			name={name}
			id={name}
			aria-label={label}
			onChange={handleChange}
			{...rest}
		/>
		<label htmlFor={name}>{label}</label>
	</div>
);

export default Checkbox;
