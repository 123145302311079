import { LINKS, countryList } from '@utils/constants';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import Checkbox from './forms/Checkbox';
import Input from './forms/Input';

interface FormProps {
	name?: string;
	company?: string;
	email?: string;
	event?: string;
	fluiddynamics?: boolean;
	structuralmechanics?: boolean;
	systemsengineering?: boolean;
	marketing?: boolean;
}

const EventForm = ({ name, title, event }: { name: string; title?: string; event?: string }) => {
	const [formSent, setFormSent] = useState(false);
	const [formData, setFormData] = useState<FormProps>();

	const encode = (data: any) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': name,
				'event': event,
				...formData,
			}),
		})
			.then((res) => {
				setFormSent(true);
			})
			.catch((error) => alert(error));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		let value;

		switch (e.target.type) {
			case 'checkbox':
				value = e.target.checked ? e.target.checked : false;
				break;

			default:
				value = e.target.value;
				break;
		}

		setFormData({ ...formData, [name]: value });
	};

	const { t } = useTranslation();

	return formSent ? (
		<p className="alert--success">
			{t("_EventFormSuccess") /* i18next-extract-disable-line */}
		</p>
	) : (
		<form
			name={name}
			data-netlify="true"
			netlify-honeypot="bot-field"
			method="POST"
			className="form__container has-loaded"
			onSubmit={(e) => handleSubmit(e)}>
			<>
				{title && <h2>{title}</h2>}
				<label hidden>
					Don&apos;t fill this out if you&apos;re human:{' '}
					<input name="bot-field" />
				</label>
				<input type="hidden" name="form-name" value={name} />
				<input type="hidden" name="subject" data-remove-prefix value="Form submission from event registration form" />
				<input type="hidden" name="event" value={event} />
				<fieldset className='form__fieldset'>
					<legend>{t("_FormDetails") /* i18next-extract-disable-line */}</legend>
					<Input
						label={t("_FormName") /* i18next-extract-disable-line */}
						name="name"
						handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(e)
						}
						className={formData?.name ? 'form-input--filled' : ''}
						required
					/>
					<Input
						label={t("_FormCompany") /* i18next-extract-disable-line */}
						name="company"
						handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(e)
						}
						className={formData?.company ? 'form-input--filled' : ''}
						required
					/>
					<Input
						label={t("_FormEmail") /* i18next-extract-disable-line */}
						name="email"
						type="email"
						className={
							formData?.email !== undefined
								? 'form-input--filled'
								: ''
						}
						handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(e)
						}
						required
					/>
				</fieldset>

				<fieldset className='form__fieldset'>
					<legend>{t("_FormInterests") /* i18next-extract-disable-line */}</legend>
					<Checkbox
						label={t("_FluidDynamics") /* i18next-extract-disable-line */}
						name="fluiddynamics"
						handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(e)
						}
					/>
					<Checkbox
						label={t("_StructuralMechanics") /* i18next-extract-disable-line */}
						name="structuralmechanics"
						handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(e)
						}
					/>
					<Checkbox
						label={t("_SystemsEngineering") /* i18next-extract-disable-line */}
						name="systemsengineering"
						handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(e)
						}
					/>
				</fieldset>

				<p>
					<Trans i18nKey="_FormPrivacy">
						{t("_FormPrivacyNotice") /* i18next-extract-disable-line */}{' '}
						<Link to={LINKS.PRIVACY} target="_blank">
							{t("_FormPrivacyLink") /* i18next-extract-disable-line */}
						</Link>
						.
					</Trans>
				</p>

				<Checkbox
					label={t("_FormMarketing") /* i18next-extract-disable-line */}
					name="marketing"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className='form-row--checkbox--small'
				/>

				<button className="button" type="submit">
					{t("_FormSubmit") /* i18next-extract-disable-line */}
				</button>
			</>
		</form>
	);
};

export default EventForm;
