import classnames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	type?: 'text' | 'email' | 'tel';
	className?: string;
	placeholder?: string;
	name: string;
	handleChange: any;
}

const Input: React.FC<InputProps> = ({
	label,
	type = 'text',
	className = '',
	placeholder,
	name,
	handleChange,
	...rest
}) => {
	return (
		<div
			className={classnames('form-row', {
				[`form-row--${type}`]: type,
				[className]: !!className,
			})}>
			<input
				type={type}
				name={name}
				id={name}
				placeholder={placeholder}
				className="form-input"
				aria-label={label}
				data-nice-name={label}
				onChange={handleChange}
				{...rest}
			/>
			{label && (
				<label htmlFor={name} className="form-label">
					<span className="form-label-span">{label}</span>
				</label>
			)}
		</div>
	);
};

export default Input;
