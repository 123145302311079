// i18next-extract-mark-ns-start event-registration

import { graphql } from 'gatsby';
import React, { useState } from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import EventForm from '@components/EventForm';
import { useAuth0 } from '@auth0/auth0-react';

const EventRegistrationPage = ({
    pageContext: {
        breadcrumb: { crumbs },
    },
    data,
    location,
}: {
    pageContext: {
        breadcrumb: { crumbs: Breadcrumb[] };
    };
    data: any;
    location: any;
}) => {
    const { t } = useTranslation();

    const params = new URLSearchParams(location.search);
    const event = params.get("event");

    return (
        <Page>
            <SEO
                title={t("seo_title")}
                description={t("seo_description")}
            />

            <Banner
                title={t("title")}
                image={{
                    src: data.banner.childImageSharp.gatsbyImageData,
                    alt: '',
                }}
                size="standard"
            />

            <Breadcrumbs crumbs={crumbs} />

            <PageSection theme="greytint" layout="fullwidth">
                <div className="col xl6 offset-xl3 l8 offset-l2 m10 offset-m1 s12 offset-s0">
                    <EventForm
                        name="event"
                        event={event || ""}
                        title={t("_RegisterForEvent")}
                    />
                </div>
            </PageSection>
        </Page>
    );
};

export default EventRegistrationPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["event-registration", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/contact-us/Realis-Simulation_ContactUs.jpg" }) {
		...imageBreaker
	}
}
`;

